<template>
  <v-container fluid>
    <TableViewComponent
      title="Table statuts présence pour le travail"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un statut"
      itemLabel="statut"
      itemsLabel="statuts"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce statut ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '11', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { FitForWorkStatusService } from "@/service/user/fit_for_work_status_service.js"

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableFitForWorkStatus",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service de la vue */
      service: null,

      /**les éléments */
      entities: [],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.fitForWorkStatus.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];
        // Récupère tous les statut
        let entities = await this.service.getAll();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.fitForWorkStatus.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.fitForWorkStatus.edit.root + "/" + entity.id
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du statut : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditUser];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditUser, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadUser];
    },
  },
  mounted() {
    
    // Instanciation des services
    this.service = new FitForWorkStatusService(this.$api.getFitForWorkStatusApi());

    this.load();
  },
};
</script>

<style>
</style>